import type { SharedCTAProps } from '@/components/sections/my24/shared/cta';
import {
  MENU_LCV_ABOUT_CONTACT,
  MENU_LCV_CALCULATOR,
  MENU_LCV_REGISTER,
} from '@/contents/lcv/link';

export const getCta = (
  page:
    | 'home'
    | 'v-cross'
    | 'x-series'
    | 'mu-x'
    | 'pickup-2-door'
    | 'pickup-4-door'
    | 'spark'
) => {
  switch (page) {
    // case 'x-series':
    //   // return getProductCtaPup('?tag=x-series');
    // case 'v-cross':
    //   // return getProductCtaPup('?tag=v-cross');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6086e8b8b14bd693/650a549aff69780bd71c4122/v-cross.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt697f8d43d3fc83db/650a54b7b556bd7e2fd3b6d4/v-cross.jpg',
    //     '/v-cross-4-door-financial'
    //   );
    // case 'pickup-4-door':
    //   // return getProductCtaPup('?tag=hilander&tag=cab4');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt50396d0288863d76/650a5403c5537cf12c4d034c/4-door.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt07b9a569da35dd53/650a52cab29ddbdf60544d62/4-door.jpg',
    //     '/hilander-4-doors-financial'
    //   );
    // case 'pickup-2-door':
    //   // return getProductCtaPup('?tag=spacecab');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfc80906da208b0d6/651fb86662df4415576dc3c6/2-door.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta0a5cee339d2ba81/651fb891705ef39e5348b5bd/2-door.jpg',
    //     '/spacecab-s-financial'
    //   );
    // case 'spark':
    //   // return getProductCtaPup('?tag=spark');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd7dadc5cd4a0b21b/650a544b7e03024b28bbe95b/spark.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7d9d47398075cb40/650a53bef0b16bdb0e1d9253/spark.jpg',
    //     '/spark-1-9-financial'
    //   );
    // case 'mu-x':
    //   return getProductCtaPpv();
    default:
      return getCtaDefault();
  }
};

const getCtaDefault = (): SharedCTAProps => {
  return {
    active: true,
    headline: {
      title: 'CTA',
    },
    items: [
      {
        preTitle: 'Register',
        title: 'ສົນໃຈລົດ / <span><br /></span>ທົດລອງຂັບ',
        description: `ລົງທະບຽນສົນໃຈລົດອີຊູຊຸ<span>ຫຼືທົດລອງຂັບบ</span>`,
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt796778f9c2e2ebb1/679053a2d46d45418bcdc8a1/laos-cta-register-mb.jpg',
          alt: 'Register',
          width: 1080,
          height: 608,
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt35b42fecedf5596f/679053a2e92e09901dc64b8e/laos-cta-register-dt.jpg',
          alt: 'Register',
          width: 1080,
          height: 1440,
        },
        buttons: [
          {
            label: 'ເບິ່ງລາຍລະອຽດ',
            url: MENU_LCV_REGISTER.url,
            target: MENU_LCV_REGISTER.target,
            icon: 'arrow-forward',
            isMinWidth: true,
            color: 'button-white',
          },
        ],
      },
      {
        preTitle: 'Calculator',
        title: 'ຄໍານວນຄ່າງວດ',
        description: `ລອງຄິດໄລ່ຄ່າງວດທີ່ຕ້ອງຈ່າຍ`,
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfff498986e207a78/679053a279558ded2a4d08c3/laos-cta-cal-mb.jpg',
          alt: 'Calculator',
          width: 1080,
          height: 608,
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte0576d108a2e8290/679053a2a949fd8bddede9f2/laos-cta-cal-dt.jpg',
          alt: 'Calculator',
          width: 1080,
          height: 1440,
        },
        buttons: [
          {
            label: 'ເບິ່ງລາຍລະອຽດ',
            url: MENU_LCV_CALCULATOR.url,
            target: MENU_LCV_CALCULATOR.target,
            icon: 'arrow-forward',
            isMinWidth: true,
            color: 'button-white',
          },
        ],
      },
      {
        preTitle: 'Contact Us',
        title: 'ຕິດຕໍ່ເຮົາ',
        description: 'ຊອກຫາຕົວແທນອີຊູຊຸໃກ້ທ່ານ',
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta013664c15e12cd8/657c04c50f816d37bf4d4418/laos-cta-contact-m.jpg',
          alt: 'Contact Us',
          width: 1080,
          height: 608,
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt20870fcc642ab34a/657c04c47537fe98ef23cd2d/laos-cta-contact.jpg',
          alt: 'Contact Us',
          width: 1080,
          height: 1440,
        },
        buttons: [
          {
            label: 'ເບິ່ງລາຍລະອຽດ',
            url: MENU_LCV_ABOUT_CONTACT.url,
            target: MENU_LCV_ABOUT_CONTACT.target,
            icon: 'arrow-forward',
            isMinWidth: true,
            color: 'button-white',
          },
        ],
      },
    ],
    dataTrack: 'lcv-home',
  };
};
