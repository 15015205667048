import {
  MUX_DATA,
  PICKUP_4DOOR_DATA,
  SPARK_DATA,
  V_CROSS_DATA,
} from '@/contents/lcv/product/cafe/lineup';
import { IMy24LineupItem } from '@/types/shared';

export const HOME_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt671b3e6508f48789/6785e119bf0f68738e69fb42/laos-my25-v-cross.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0c014233d3595af1/678def9fbdbc7cdbae82eb90/laos-my25-lineup-v-cross-mb.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7b8a4c600563e739/678defa0d8a19e78bd1b2ca4/laos-my25-lineup-v-cross-dt.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: V_CROSS_DATA.url,
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta51ed2a1ba666cd1/67932801e8d2d16219754fcc/laos-my25-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf1eba0f0efca910/678defa034a9436507796241/laos-my25-lineup-4door-mb.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8e82d6c7d27f5baf/678defa0a057854355770ddd/laos-my25-lineup-4door-dt.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: PICKUP_4DOOR_DATA.url,
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5ddebdd4e98a9dcd/6785e11926c6b478453f02ba/laos-my25-spark.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ee34443e413351/678def9fd9e01543f995f0f1/laos-my25-lineup-spark-mb.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt637a19dcd9a49c0d/678defa13b42133ff9059e18/laos-my25-lineup-spark-dt.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: SPARK_DATA.url,
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    id: 5,
    preTitle: 'MU-X',
    title: MUX_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2d91f0105faf638c/6785e1191cd05fa5ce4b19dd/laos-my25-mu-x.png',
      alt: MUX_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3d1c41c7d903097c/679052e4259b9a0f95266892/laos-my25-lineup-mu-x-mb.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf5bda3f0a6d6ffc5/679052e5d9e01551d29606bc/laos-my25-lineup-mu-x-dt.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: MUX_DATA.url,
      isMinWidth: true,
    },
    price: MUX_DATA.price,
  },
];

export const PRODUCT_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt671b3e6508f48789/6785e119bf0f68738e69fb42/laos-my25-v-cross.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0c014233d3595af1/678def9fbdbc7cdbae82eb90/laos-my25-lineup-v-cross-mb.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7b8a4c600563e739/678defa0d8a19e78bd1b2ca4/laos-my25-lineup-v-cross-dt.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: V_CROSS_DATA.url,
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta51ed2a1ba666cd1/67932801e8d2d16219754fcc/laos-my25-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf1eba0f0efca910/678defa034a9436507796241/laos-my25-lineup-4door-mb.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8e82d6c7d27f5baf/678defa0a057854355770ddd/laos-my25-lineup-4door-dt.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: PICKUP_4DOOR_DATA.url,
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5ddebdd4e98a9dcd/6785e11926c6b478453f02ba/laos-my25-spark.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ee34443e413351/678def9fd9e01543f995f0f1/laos-my25-lineup-spark-mb.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt637a19dcd9a49c0d/678defa13b42133ff9059e18/laos-my25-lineup-spark-dt.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: SPARK_DATA.url,
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    id: 6,
    preTitle: 'MU-X',
    title: MUX_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2d91f0105faf638c/6785e1191cd05fa5ce4b19dd/laos-my25-mu-x.png',
      alt: MUX_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3d1c41c7d903097c/679052e4259b9a0f95266892/laos-my25-lineup-mu-x-mb.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf5bda3f0a6d6ffc5/679052e5d9e01551d29606bc/laos-my25-lineup-mu-x-dt.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: MUX_DATA.url,
      isMinWidth: true,
    },
    price: MUX_DATA.price,
  },
];
